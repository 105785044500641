import React from 'react'
import { components } from 'react-select'

const { ValueContainer, SingleValue } = components

const CustomValueContainer = ({
  children,
  selectProps,
  innerProps,
  ...props
}: any) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps,
    theme: props.theme,
    data: '',
    innerProps: props,
  }

  return (
    <ValueContainer {...props} selectProps={selectProps}>
      {React.Children.map(
        children,
        (child) =>
          child ||
          (props.hasValue ? (
            <SingleValue {...commonProps} isDisabled={selectProps.isDisabled}>
              {selectProps.getOptionLabel(props.getValue()[0])}
            </SingleValue>
          ) : null)
      )}
    </ValueContainer>
  )
}

export default CustomValueContainer
