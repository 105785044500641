import { components } from 'react-select'

const { MenuList } = components

const CustomMenuListSingle = (props: any) => (
  <div className='md:py-2 rounded-b-lg'>
    <MenuList {...props} selectProps={props.selectProps} />
  </div>
)

export default CustomMenuListSingle
