export const sorting = [
  {
    value: 'DEFAULT',
    label: 'default',
  },
  {
    value: 'SALARY,DESC',
    label: 'salary_desc',
  },
  {
    value: 'SALARY,ASC',
    label: 'salary_asc',
  },
  {
    value: 'PUBLISHED,ASC',
    label: 'publish_asc',
  },
  {
    value: 'PUBLISHED,DESC',
    label: 'publish_desc',
  },

  {
    value: 'TITLE,ASC',
    label: 'title_a_z',
  },
  {
    value: 'TITLE,DESC',
    label: 'title_z_a',
  },

  {
    value: 'COMPANY,ASC',
    label: 'company_asc',
  },
  {
    value: 'COMPANY,DESC',
    label: 'company_desc',
  },
]

export const sortingCompanies = [
  {
    value: 'DEFAULT',
    label: 'default',
  },
  {
    value: 'NAME,ASC',
    label: 'company_asc',
  },
  {
    value: 'NAME,DESC',
    label: 'company_desc',
  },
]
