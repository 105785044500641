const CustomOption = ({
  innerProps,
  isDisabled,
  children,
  isSelected,
}: any) => (
  <div
    {...innerProps}
    className={`select__option py-5 md:py-3 cursor-pointer leading-6 border-b border-gray text-md  relative ${
      isSelected ? 'select__option--selected' : ''
    }`}
  >
    {children}
    <span className=' border border-gray-300 rounded-full w-6 h-6 absolute right-0 top-5 md:top-3 flex justify-center items-center'>
      {isSelected && (
        <span className='inline-block bg-black rounded-full w-4 h-4' />
      )}
    </span>
  </div>
)

export default CustomOption
