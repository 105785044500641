import { components } from 'react-select'
import { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import {
  ArrowSmallLeftIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'

const { MenuList } = components

const CustomFixedMenuList = (props: any) => {
  const { onInputChange, inputValue, onFocus } = props.selectProps

  const [title, setTitle] = useState<string>()
  const { t, lang } = useTranslation('common')

  const handleAddOption = () => {
    props.setValue(
      props.getValue().concat([{ value: inputValue, label: inputValue }]),
      { action: 'input-change' }
    )
  }
  const addNew = props.selectProps.hasOwnProperty('addNew')
    ? props.selectProps.addNew
    : true
  useEffect(() => {
    setTitle(document.querySelector('.basic-single .opened .title')?.innerHTML)
  }, [])

  const handleClear = () => {
    props.setValue(props.getValue())
  }

  return (
    <div className='md:py-2 rounded-b-lg'>
      <h2
        className='font-medium text-2xl py-5 pl-5 bg-white md:hidden pointer-events-none absolute w-full'
        style={{ top: '-52px' }}
      >
        <ArrowSmallLeftIcon width={14} className='mr-4 w-8' />
        {title}
      </h2>
      <div className='relative mx-5'>
        <MagnifyingGlassIcon
          width={18}
          className={`absolute left-4 top-5 md:top-3 my-3  ${
            props.children.length > 0 ? 'opacity-25' : 'opacity-10'
          }`}
        />
        <input
          className='pl-11 pr-2 py-4 md:py-2 mb-5 my-3 rounded-md w-full bg-gray md:bg-white'
          autoCorrect='off'
          autoComplete='off'
          spellCheck='false'
          type='text'
          value={inputValue}
          onChange={(e) => {
            onInputChange(e.currentTarget.value, {
              action: 'input-change',
            })
          }}
          onKeyPress={(e) => {
            const keyCode = e.code || e.key

            ;(e.target as HTMLElement).focus()
            if (keyCode === 'Enter') {
              if (!addNew) return false

              e.preventDefault()
              handleAddOption()
              return false
            }
          }}
          onMouseDown={(e: React.SyntheticEvent) => {
            e.stopPropagation()
            ;(e.target as HTMLElement).focus()
          }}
          onTouchEnd={(e) => {
            e.stopPropagation()
            ;(e.target as HTMLElement).focus()
          }}
          onFocus={onFocus}
          placeholder={
            props.children.length > 0
              ? `${t('common:search')}...`
              : `${t('common:enter')}...`
          }
        />
        {!props.children.length && addNew && (
          <PlusIcon
            width={18}
            className='text-2xl absolute right-12 top-5 opacity-50 hover:opacity-100 cursor-pointer'
            onClick={handleAddOption}
          />
        )}
        {inputValue.length > 0 && (
          <XMarkIcon
            width={18}
            className='text-2xl absolute right-3 top-5 opacity-50 hover:opacity-100 cursor-pointer'
            onClick={handleClear}
          />
        )}
      </div>
      <div className='listMenu-wrapper'>
        <MenuList
          {...props}
          selectProps={props.selectProps}
          className='border-t-8 border-gray md:border-0 '
        />
      </div>
    </div>
  )
}

export default CustomFixedMenuList
