import { useEffect } from 'react'
import Router, { NextRouter } from 'next/router'

function saveScrollPos(asPath: string, idx: number) {
  sessionStorage.setItem(
    `scrollPos:${idx}`,
    JSON.stringify({
      x: window.scrollX,
      y: window.scrollY,
      asPath: asPath,
      idx: idx,
    })
  )
}

function restoreScrollPos(asPath: string, idx: number) {
  const json = sessionStorage.getItem(`scrollPos:${idx}`)
  const scrollPos = json ? JSON.parse(json) : undefined
  if (scrollPos && scrollPos.idx === idx && scrollPos.asPath === asPath) {
    window.scrollTo(scrollPos.x, scrollPos.y)
  } else {
    window.scrollTo(0, 0)
  }
}

export function useScrollRestoration(router: NextRouter) {
  useEffect(() => {
    if (!('scrollRestoration' in window.history)) return
    let shouldScrollRestore = false
    window.history.scrollRestoration = 'manual'
    restoreScrollPos(router.asPath, window.history.state.idx)

    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      saveScrollPos(router.asPath, window.history.state.idx)
      delete event.returnValue
    }

    const onRouteChangeStart = () => {
      saveScrollPos(router.asPath, window.history.state.idx)
    }

    const onRouteChangeComplete = (url: string) => {
      if (shouldScrollRestore) {
        shouldScrollRestore = false
        /**
         * Calling with relative url, not expected asPath, so this
         * will break if there is a basePath or locale path prefix.
         */
        restoreScrollPos(url, window.history.state.idx)
      }
    }

    window.addEventListener('beforeunload', onBeforeUnload)
    Router.events.on('routeChangeStart', onRouteChangeStart)
    Router.events.on('routeChangeComplete', onRouteChangeComplete)
    Router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        shouldScrollRestore = true
      }
      return true
    })

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
      Router.events.off('routeChangeStart', onRouteChangeStart)
      Router.events.off('routeChangeComplete', onRouteChangeComplete)
      Router.beforePopState(() => true)
    }
  }, [router])
}
