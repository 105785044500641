import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import useTranslation from 'next-translate/useTranslation'
import CustomMenuList from './CustomMenuList'
import CustomValueContainer from './CustomValueContainer'
import ControlComponent from './ControlComponent'
import CustomMultiValueContainer from './CustomMultiValueContainer'
import CustomOption from './CustomOption'
import CustomMenuListSingle from './CustomMenuListSingle'
import CustomOptionSingle from './CustomOptionSingle'
import { JobStateFilters } from '@/context/jobListContext'
import styles from './Select.module.scss'
import CustomFixedMenuList from './CustomFixedMenuList'

const colourStyles = {
  control: (styles: any) => ({
    ...styles,
    borderWidth: 0,
    outline: 'none',
    boxShadow: 'none',
    minHeight: 0,
    background: 'transparent',
  }),
  menu: (styles: any) => ({
    ...styles,
    border: '3px solid #000',
    borderTop: 0,
    margin: '-3px 0 0 0',
    boxShadow: '0 0 0px 0 #000',
    borderRadius: ' 0 0 10px 10px',
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    whiteSpace: 'nowrap',
    display: 'block',
    overflow: 'hidden',
    maxWidth: '300px',
    textOverflow: 'ellipsis',
    padding: 0,
    background: 'transparent',
  }),
  menuList: (styles: object) => ({
    ...styles,
    background: '#fff',
    padding: '0 20px',
  }),
  option: (
    styles: object,
    { isDisabled, isFocused }: { isDisabled: boolean; isFocused: boolean }
  ) => ({
    ...styles,
    backgroundColor: isDisabled ? 'red' : '#fff',
    color: '#000',
    padding: ' 15px 0',
    borderBottom: '1px solid #efefef',
    fontSize: '18px',
    cursor: isDisabled ? 'not-allowed' : isFocused ? 'pointer' : 'default',
  }),
}

export type SelectDataType = {
  value: string
  label: string
}

interface IMultiSelect {
  name: string
  data: any
  title?: string
  className?: string
  style?: CSSProperties
  value: any
  onSelect: (value: SelectDataType | null, key: string) => void
  type?: 'multi' | 'single'
  addNew?: boolean
  isSearchable?: boolean
  state: any
  setIsParentVisible?: (value: boolean) => void
}

const MultiSelect = ({
  name,
  data,
  title,
  state,
  className,
  style,
  value = null,
  onSelect,
  type = 'multi',
  addNew = true,
  isSearchable = true,
  setIsParentVisible,
  ...rest
}: IMultiSelect) => {
  const containerRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [selected, setSelected] = useState<SelectDataType | null>(value)
  const [isOpen, setIsOpen] = useState(false)
  const { t, lang } = useTranslation('common')
  const isMountedSelected = useRef(false)
  const isMounted = useRef(false)
  const [options, setOptions] = useState(data)

  const openMenu = (e: React.SyntheticEvent) => {
    if (!(e.target as Element).classList.contains('cleaner')) {
      setIsOpen(true)
    }
  }

  const onDomClick = (e: MouseEvent): void => {
    const menu = (containerRef.current! as Element).querySelector(
      '.select__menu'
    )

    if (!(e.target as Element).classList.contains('cleaner')) {
      if (
        !(containerRef.current! as HTMLElement).contains(e.target as Node) ||
        !menu ||
        !menu.contains(e.target as Node)
      ) {
        setIsOpen(false)
        setInputValue('')
        if (setIsParentVisible) {
          setIsParentVisible(false)
        }
      }
    }
  }

  const handleChange = (value: any) => {
    setSelected(value)
  }

  const clearSelected = (e: any) => {
    setSelected(null)
  }

  const handleCreateOption = (value: any) => {
    if (isSearchable) {
      const newOptions = [{ value: value, label: value }, ...options]
      setOptions(newOptions)
    }
    setInputValue(value)
  }

  useEffect(() => {
    if (isMountedSelected.current) {
      onSelect(selected, name as string)
    } else {
      isMountedSelected.current = true
    }
  }, [selected])

  useEffect(() => {
    if (isMounted.current) {
      if (state.filters && state.filters[name as keyof JobStateFilters]) {
        setSelected(state.filters[name as keyof JobStateFilters] as any)
      }
    } else {
      isMounted.current = true
    }
  }, [state])

  useEffect(() => {
    document.addEventListener('mousedown', onDomClick)

    return () => {
      document.removeEventListener('mousedown', onDomClick)
    }
  }, [])

  return (
    <div className={className} style={style}>
      <div ref={containerRef}>
        <Select
          {...rest}
          instanceId='multielect'
          className={`basic-single ${styles['select-dropdown']}`}
          isMulti={type === 'multi'}
          value={selected}
          classNamePrefix='select'
          name={name}
          // @ts-ignore
          addNew={addNew}
          options={options}
          noOptionsMessage={() => t('common:nothing_found')}
          components={{
            MenuList: isSearchable
              ? type === 'multi'
                ? CustomMenuList
                : CustomMenuListSingle
              : CustomFixedMenuList,
            ValueContainer: CustomValueContainer,
            Placeholder: () => null,
            Control: (props) => (
              <ControlComponent
                {...props}
                isOpen={isOpen}
                openMenu={openMenu}
                selected={selected}
                title={title}
                clearSelected={clearSelected}
              />
            ),
            ClearIndicator: () => null,
            MultiValueContainer: (props) => (
              <CustomMultiValueContainer {...props} />
            ),
            DropdownIndicator: () => null,
            MenuPortal: () => null,
            IndicatorSeparator: () => null,
            Option: type === 'multi' ? CustomOption : CustomOptionSingle,
            Input: () => null,
          }}
          inputValue={inputValue}
          isSearchable
          onChange={(e) => {
            handleChange(e)
            setIsFocused(false)
          }}
          hideSelectedOptions={false}
          onFocus={openMenu}
          onInputChange={(val) => setInputValue(val)}
          {...{
            menuIsOpen: isFocused || undefined,
            isFocused: isFocused || undefined,
          }}
          menuIsOpen={isOpen}
          styles={colourStyles}
        />
      </div>
    </div>
  )
}

export default MultiSelect
