import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { usePagination, DOTS } from '../../hooks/usePagination'

interface IProps {
  onPageChange: (value: number) => void
  totalCount: number
  siblingCount?: number
  currentPage: number
  pageSize: number
  className: string
  type?: 'light' | 'dark'
}

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
  type = 'light',
}: IProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  const classes = {
    activeClass: type === 'dark' ? 'bg-white' : 'bg-gray-100',
  }

  if (!paginationRange) return null

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }
  const lastPage = paginationRange[paginationRange.length - 1]

  const onNext = () => {
    if (currentPage === lastPage) {
      onPageChange(lastPage)
    } else {
      onPageChange(currentPage + 1)
    }
  }

  const onPrevious = () => {
    if (currentPage === 1) {
      onPageChange(currentPage)
    } else {
      onPageChange(currentPage - 1)
    }
  }

  return (
    <ul className={`flex justify-center items-center my-10 ${className}`}>
      <li
        className={`mr-4 cursor-pointer hover:text-primary ${
          currentPage === 1 && 'opacity-10'
        }`}
        onClick={onPrevious}
      >
        <ChevronLeftIcon className='h-5' />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li
              key={index}
              className='w-10 h-10 mx-1 rounded-full flex items-center justify-center cursor-pointer'
            >
              &#8230;
            </li>
          )
        }

        return (
          <li
            key={index}
            className={`w-10 h-10 mx-1 rounded-full flex items-center justify-center cursor-pointer hover:bg-gray-100 ${
              pageNumber === currentPage && classes.activeClass
            }`}
            onClick={() => onPageChange(pageNumber as number)}
          >
            {pageNumber}
          </li>
        )
      })}
      <li
        className={`ml-4 cursor-pointer hover:text-primary ${
          currentPage === lastPage && 'opacity-10'
        }`}
        onClick={onNext}
      >
        <ChevronRightIcon className='h-5' />
      </li>
    </ul>
  )
}

export default Pagination
