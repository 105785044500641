import { arrayToString, isEmpty } from '@/helpers/utils'
import {
  Job,
  JobFilters,
  JobsSearchOrderBy,
  JobsSearchOrderField,
  Salary_Brackets,
} from 'generated/apolloComponents'
import { createContext } from 'react'

export type SelectType = {
  label: string
  value: string
}

export type JobStateFilters = {
  city?: SelectType[]
  locationDistance?: string
  experienceLevel?: string[]
  employmentType?: string[]
  role?: SelectType[]
  salaryBrackets?: Salary_Brackets
  skills?: SelectType[]
  topTech?: boolean
  withSalary?: boolean
  ukraineRelocation?: boolean
  companyIds?: Number[]
  company?: string[]
}

// State
export type OrderStateType = {
  label: string
  value: JobsSearchOrderBy
}

export interface JobListState {
  page: number
  perPage: number
  jobList?: Job[]
  filters?: JobStateFilters
  order?: OrderStateType
  totalCount?: number
}

export const initialJobListState: JobListState = {
  page: 1,
  perPage: 50,
  jobList: [],
  filters: {
    city: [],
    experienceLevel: [],
    role: [],
    salaryBrackets: undefined,
    skills: [],
    withSalary: false,
    ukraineRelocation: false,
  },
  order: undefined,
}

// Actions
export enum ActionType {
  ChangeFilter,
  ChangePage,
  ChangeOrder,
  SetJobsList,
  SetState,
}

export interface ChangeFilter {
  type: ActionType.ChangeFilter
  payload: JobStateFilters
}

export interface ChangePage {
  type: ActionType.ChangePage
  payload: number
}

export interface ChangeOrder {
  type: ActionType.ChangeOrder
  payload: OrderStateType
}

export interface SetJobsList {
  type: ActionType.SetJobsList
  payload: Job[]
}

export interface SetState {
  type: ActionType.SetState
  payload: JobListState
}

export type JobListActions =
  | ChangeFilter
  | ChangePage
  | ChangeOrder
  | SetJobsList
  | SetState

// Reducer
export function jobListReducer(
  state: JobListState,
  action: JobListActions
): JobListState {
  switch (action.type) {
    case ActionType.ChangeFilter:
      return { ...state, filters: action.payload, page: 1 }
    case ActionType.ChangePage:
      return { ...state, page: action.payload }
    case ActionType.ChangeOrder:
      return { ...state, order: action.payload }
    case ActionType.SetJobsList:
      return { ...state, jobList: action.payload }
    case ActionType.SetState:
      return action.payload
    default:
      return state
  }
}

// Context
export const JobListContext = createContext<{
  state: JobListState
  dispatch: React.Dispatch<JobListActions>
}>({
  state: initialJobListState,
  dispatch: () => undefined,
})

export const convertJobStateFiltersToQueryFilters = (
  filters: JobStateFilters
): JobFilters => {
  const parsed: JobFilters = {}

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      switch (key) {
        case 'city':
        case 'skills':
        case 'role':
          parsed[key] = (value as SelectType[]).map(
            (elem: SelectType) => elem['value']
          )
          break
        case 'experienceLevel':
        case 'employmentType':
        case 'company':
          parsed[key] = value as string[]
          break
        case 'locationDistance':
          parsed['locationDistance'] = value as string
          break
        case 'salaryBrackets':
          parsed[key] = value as Salary_Brackets
          break
        case 'withSalary':
          parsed['withSalary'] = value as boolean
          break
        case 'ukraineRelocation':
          parsed['ukraineRelocation'] = value as boolean
          break
        case 'topTech':
          parsed['topTech'] = value as boolean
          break
        case 'companyIds':
          parsed['companyIds'] = (value as SelectType[]).map(
            (elem: SelectType) => Number(elem['value'])
          )
          break
        default:
      }
    }
  })

  return parsed
}

// Convert filters slug to URL
const camelToSnakeCase = (str: string, sneakCase: boolean) => {
  if (!sneakCase) return str
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export const convertJobsFiltersSlugToURL = (
  state: JobListState,
  sneakCase?: boolean,
  callback?: Function
): string => {
  let page: number | null = state.page ? state.page : null
  const slugParts: string[] = []
  const filters: JobStateFilters | null = state.filters ? state.filters : null
  const useSnakeCase = sneakCase || false

  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        let tmp: string | undefined

        switch (key) {
          case 'city':
          case 'skills':
          case 'companyIds':
          case 'role':
            if ((filters[key] as SelectType[]).length !== 0) {
              tmp = arrayToString(
                filters[key] as SelectType[],
                'value',
                callback
              )
              slugParts.push(`${camelToSnakeCase(key, useSnakeCase)},${tmp}`)
            }
            break
          case 'experienceLevel':
          case 'employmentType':
            if ((filters[key] as string[]).length !== 0) {
              tmp = arrayToString(filters[key] as string[])
              slugParts.push(`${camelToSnakeCase(key, useSnakeCase)},${tmp}`)
            }
            break
          case 'salaryBrackets':
            if (!isEmpty(filters[key] as Salary_Brackets)) {
              slugParts.push(
                `${camelToSnakeCase(key, useSnakeCase)},${filters[key]?.min},${
                  filters[key]?.max
                }`
              )
            }
            break
          case 'withSalary':
          case 'topTech':
          case 'company':
          case 'locationDistance':
          case 'ukraineRelocation':
            if (filters[key])
              slugParts.push(
                `${camelToSnakeCase(key, useSnakeCase)},${filters[key]}`
              )
            break
          default:
        }
      }
    })
  }

  if (
    state.order &&
    state.order.value &&
    state.order.value.field !== JobsSearchOrderField.DEFAULT
  ) {
    slugParts.push(
      `order,${state.order.value.field.toLowerCase()},${state.order.value.direction?.toLowerCase()}`
    )
  }

  if (page && page > 1) {
    slugParts.push(`page,${state.page}`)
  }

  if (slugParts.length > 0) return `/s/${slugParts.join('/')}`

  return ''
}
