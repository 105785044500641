const CustomMultiValueContainer = ({ selectProps, data }: any) => {
  const label =
    selectProps.options.find((option: any) => option.value == data.value)
      ?.label || data.value
  const values: any = selectProps.value!
  if (values) {
    return (
      <span
        className='mr-1 text-sm'
        style={{ color: '#151515', fontSize: '13px' }}
      >
        {values[values.length - 1].value === data.value ? label : `${label},`}
      </span>
    )
  }
  return <></>
}

export default CustomMultiValueContainer
