import { CheckIcon } from '@heroicons/react/24/solid'

const CustomOption = ({
  innerProps,
  isDisabled,
  children,
  isSelected,
}: any) => (
  <div
    {...innerProps}
    className={`select__option py-5 md:py-3 cursor-pointer leading-6 border-b border-gray text-md relative ${
      isSelected ? 'select__option--selected' : ''
    }`}
  >
    {children}
    <CheckIcon
      width={18}
      className={`absolute right-2 ${isSelected ? 'opacity-100' : 'opacity-0'}`}
    />
  </div>
)

export default CustomOption
