import { components, ControlProps } from 'react-select'

import useTranslation from 'next-translate/useTranslation'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

interface iControlComponent extends ControlProps {
  isOpen: boolean
  openMenu: () => void
  selected: string[]
  title: string
  clearSelected: () => void
}

const ControlComponent = (props: any) => {
  const { t, lang } = useTranslation('common')

  return (
    <div
      className={`transition duration-300 border bg-white p-5 flex items-center justify-between controlComponent ${
        props.isOpen
          ? 'opened border-black rounded-t-lg shadow-select md:bg-gray'
          : 'border-gray rounded-lg'
      }`}
      onClick={props.openMenu}
    >
      <div className='' style={{ maxWidth: '90%' }}>
        <p className='text-md flex items-center'>
          <span className='title pointer-events-none'>{props.title}</span>

          {props.selected && props.selected.length ? (
            <span
              className='cleaner cursor-pointer relative ml-3 inline-block text-xs text-white bg-black px-2 py-1 leading-tight rounded-md hover:bg-purple transition duration-200'
              onClick={props.clearSelected}
            >
              {t('common:clear')}
            </span>
          ) : (
            ''
          )}
        </p>
        <components.Control {...props} />
      </div>
      <div>
        {props.isOpen ? (
          <ChevronUpIcon className='w-5' />
        ) : (
          <ChevronDownIcon className='w-5' />
        )}
      </div>
    </div>
  )
}

export default ControlComponent
