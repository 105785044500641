/* eslint-disable react/no-danger */
import { Metadata } from 'generated/apolloComponents'
import Head from 'next/head'

interface IProps {
  metaData: Metadata
  noindex?: boolean
}

const MetaInfo = ({ metaData, noindex = false }: IProps) => {
  const { jsonLd, breadcrumbJsonLd } = metaData

  return (
    <Head>
      {metaData.title && metaData.title?.length > 0 ? (
        <title>{metaData.title}</title>
      ) : null}
      {(noindex || metaData.noindex) && (
        <meta name='robots' content='noindex' />
      )}
      {metaData.description && metaData.description?.length > 0 ? (
        <meta name='description' content={metaData.description} />
      ) : null}
      {metaData.hreflangPlUrl && metaData.hreflangPlUrl?.length > 0 ? (
        <link rel='alternate' href={metaData.hreflangPlUrl} hrefLang='pl' />
      ) : null}
      {metaData.hreflangEnUrl && metaData.hreflangEnUrl?.length > 0 ? (
        <link rel='alternate' href={metaData.hreflangEnUrl} hrefLang='en' />
      ) : null}
      {metaData.canonicalUrl && metaData.canonicalUrl?.length > 0 ? (
        <link rel='canonical' href={metaData.canonicalUrl} />
      ) : null}
      {metaData.title && metaData.title?.length > 0 ? (
        <meta property='og:title' content={metaData.title} />
      ) : null}
      {metaData.description && metaData.description?.length > 0 ? (
        <meta property='og:description' content={metaData.description} />
      ) : null}
      {metaData.imageUrl && metaData.imageUrl?.length > 0 ? (
        <meta property='og:image' content={metaData.imageUrl} />
      ) : null}
      {typeof window !== 'undefined' ? (
        <meta property='og:url' content={window.location.href} />
      ) : null}
      <meta property='og:type' content='website' />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@bulldogjob' />

      {jsonLd && jsonLd?.length > 0 ? (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: jsonLd }}
        />
      ) : null}
      {breadcrumbJsonLd && breadcrumbJsonLd?.length > 0 ? (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: breadcrumbJsonLd }}
        />
      ) : null}
    </Head>
  )
}

export default MetaInfo
